import _ from 'lodash';
import IBillingReport from '@/entities/IBillingReport';
import IServiceBillingReport, { IServiceLenderDetails } from '@/services/api/models/IServiceBillingReport';
import IFile from '@/entities/IFile';

export default class BillingReport implements IBillingReport {
  billingReportsId: string;
  timestamp: string;
  lenderId: string;
  active?: boolean;
  reportName: string;
  reportLink: string;
  billSentDate?: string;
  billIssuedBy?: any;
  invoiceNumber?: string;
  paid?: boolean;
  parcelIds?: any;
  totalCost?: string;
  amountPaid?: any;
  lender?: IServiceLenderDetails;

  // Files
  files?: IFile[] = [];

  constructor(serviceObj: IServiceBillingReport) {
    this.billingReportsId = serviceObj.billing_reports_id;
    this.timestamp = serviceObj.timestamp;
    this.lenderId = serviceObj.lender_id;
    this.active = serviceObj.active;
    this.reportName = serviceObj.report_name;
    this.reportLink = serviceObj.report_link;
    this.billSentDate = serviceObj.bill_sent_date;
    this.billIssuedBy = serviceObj.bill_issued_by;
    this.invoiceNumber = serviceObj.invoice_number;
    this.paid = serviceObj.paid;
    this.parcelIds = serviceObj.parcel_ids;
    this.totalCost = serviceObj.total_cost;
    this.amountPaid = serviceObj.amount_paid;
    this.lender = serviceObj.lender;
    this.files = serviceObj.files || [];
  }
}
