var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","id":"container"}},[_c('v-row',{staticClass:"mr-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Loans")]),_c('v-data-table',{attrs:{"headers":_vm.loanHeaders,"items":_vm.reports,"sort-by":'loanNumber',"sort-desc":false,"fixed-header":true},scopedSlots:_vm._u([{key:"item.totalCost",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currency")(item.totalCost)))]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Adjustment Items")]),_c('v-data-table',{attrs:{"headers":_vm.adjustmentHeaders,"items":_vm.adjustmentReports,"fixed-header":true},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currency")(item.value)))]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.date))]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Totals")]),_c('v-data-table',{attrs:{"headers":_vm.totalHeaders,"items":_vm.totals,"fixed-header":"","hide-default-footer":"","id":"totals-table"},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currency")(item.value)))]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }