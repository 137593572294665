



































import {
  Component,
  Prop,
  Emit,
  Vue,
  Mixins,
  Model,
  Watch,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';

import _ from 'lodash';

@Component({
  name: 'date-picker',
})
export default class DatePicker extends Vue {
  @Prop({
    type: String,
    default: 'Date',
  }) private readonly label!: string;

  @Prop({
    type: String,
    default: 'MM/dd/yyyy',
  }) private readonly format!: string;

  @Prop({
    type: String,
    default: '',
  }) private readonly textStyling!: string;

  private menu: boolean = false;

  private date: string = '';

  // Computed
  get formattedDate() {
    if (!this.date) {
      return '';
    }

    return DateTime.fromISO(this.date).toFormat(this.format);
  }

  set formattedDate(newValue) {
    this.updateDate(newValue, this.format);
  }

  menuSaved() {
    this.menu = false;
  }

  parseDate(dateString: string, format: string): string {
    return DateTime.fromFormat(dateString, format).toISO();
  }

  @Emit('input')
  updateDate(dateString: string, format: string = 'yyyy-MM-dd') {
    this.date = this.parseDate(dateString, format);
    return this.date;
  }
}
