










































import { Component, Prop, Vue } from 'vue-property-decorator';
import { capitalCase } from 'change-case';

import { LineItem } from '@/views/billing/ReportSummary.vue';

@Component({
  name: 'billing-report',
})
export default class BillingReport extends Vue {
  @Prop({
    type: Array,
    default: (): any[] => [],
  }) private readonly reports!: any[];

  @Prop({
    type: Array,
    default: (): LineItem[] => [],
  }) private readonly adjustments!: LineItem[];

  @Prop({
    type: Array,
    default: (): string[] => [],
  }) private readonly optionals!: string[];

  private defaultLoanHeaders: any[] = [
    { text: 'Loan Number', value: 'loanNumber', sortable: true },
    { text: 'E/N', value: 'type', sortable: true },
    { text: 'Total Parcels', value: 'parcelCount', sortable: true },
    { text: 'Parcels in This Bill', value: 'billForCount', sortable: true },
    { text: 'Units Charged', value: 'unitsCharged', sortable: true },
    { text: 'Total Cost', value: 'totalCost', sortable: true },
    { text: 'Name/Company Name', value: 'name', sortable: true },
  ];

  private adjustmentHeaders: any[] = [
    { text: 'Description', value: 'description', sortable: true },
    { text: 'Amount', value: 'value', sortable: true },
    { text: 'Date', value: 'date', sortable: true },
  ];

  private totalHeaders: any[] = [
    { text: 'Subtotal', value: 'description', sortable: true },
    { text: 'Amount', value: 'value', sortable: true },
  ];

  // Computed
  get adjustmentReports(): any[] {
    return this.adjustments.map((lineItem: LineItem) => ({
      description: lineItem.description,
      value: lineItem.value,
      date: lineItem.date
        ? lineItem.date
        : null,
    }));
  }

  get loanHeaders(): any[] {
    const headers = [].concat(this.defaultLoanHeaders);

    headers.push(
      ...this.optionals.map((optional) => ({
        text: capitalCase(optional),
        value: optional,
        sortable: false,
      })),
    );

    return headers;
  }

  get totals(): any[] {
    const totals = [];

    totals.push({
      description: 'Loans',
      value: this.calculateTotal(this.reports, 'totalCost'),
    });

    totals.push({
      description: 'Adjustment Items',
      value: this.calculateTotal(this.adjustments),
    });

    totals.push({
      description: 'Grand Total',
      value: this.calculateTotal(totals),
    });

    return totals;
  }

  calculateTotal(entries: any[], key: string = 'value'): number {
    return entries.reduce((total: number, entry: any) => total + entry[key], 0);
  }
}
