
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import onlyAllowCurrency from '@/helpers/events/onlyAllowCurrency';

@Component({
  name: 'currency-formatter',
})
export default class CurrencyFormatter extends Vue {
  // Methods
  enforceCurrency($event: KeyboardEvent, value: string) {
    onlyAllowCurrency($event, value);
  }
}
