
























































































































































































































import {
  Component, Prop, Mixins,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';
import changeCase from 'change-case';
import axios from 'axios';
import { DataTableHeader } from 'vuetify';
import { debounce, sumBy } from 'lodash';

import LoanService from '@/services/loans';
import BillingReportService from '@/services/billingReports';

import { JsonPatchOperator, JsonPatchPayload } from '@/helpers/vuelidateToPatch';

import UserPermissions from '@/mixins/UserPermissions.vue';

import BillingReport from '@/entities/BillingReport';

@Component({
  name: 'billing-panel',
})
export default class BillingPanel extends Mixins(UserPermissions) {
  @Prop({
    type: Array,
    default: (): any[] => [],
  })
  private readonly reports!: any[];

  @Prop({
    type: Array,
    default: (): string[] => [],
  })
  private readonly optionals!: string[];

  private reportsSearch: string = '';
  public isLoading = true;
  public isUpdating : { [index: string]: boolean } = {};
  public currentOptions: any = {};
  public currentSearch = '';
  public currentDebounce: Function = null;

  private menu: any = {};
  private unpayDialog: any = {};
  private deleteDialog: any = {};
  private check: string = '';
  private checkDate: string = '';
  private amount: string = '';
  private rules: any = {
    required: (value: any) => !!value || 'Required.',
    validDate: (value: any) => ((DateTime.fromFormat(value, 'MM/dd/yyyy').isValid) && DateTime.fromFormat(value, 'MM/dd/yyyy').year >= 1582) || 'Must be a valid MM/DD/YYYY date',
  };

  private currentSearchBy: DataTableHeader = {
    text: 'All',
    value: 'all',
  };

  private searchCriteria: DataTableHeader[] = [
    {
      text: 'All',
      value: 'all',
    },
    {
      text: 'Invoice Number',
      value: 'invoice_number',
    },
    {
      text: 'Lender Number',
      value: 'lender_number',
    },
    {
      text: 'Lender Name',
      value: 'lender_name',
    },
    {
      text: 'Report Name',
      value: 'report_name',
    },
  ];

  private billingReports: BillingReport[];
  private processedBillingReports: any[] = [];

  private defaultBillingPanelHeaders: DataTableHeader[] = [
    {
      text: 'Report Name',
      value: 'reportName',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Invoice Number',
      value: 'invoiceNumber',
      sortable: true,
      align: 'center',
    },
    {
      text: 'Lender',
      value: 'lender',
      sortable: true,
      align: 'center',
    },
    {
      text: 'Units Billed',
      value: 'parcelsList',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Date Created',
      value: 'when_billed',
      sortable: true,
      align: 'center',
    },
    {
      text: 'Total Cost',
      value: 'totalCost',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Amount Paid',
      value: 'amountPaid',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Issuer',
      value: 'billIssuedBy',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Bill Sent',
      value: 'billSent',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Bill Sent Date',
      value: 'billSentDate',
      sortable: true,
      align: 'center',
    },
    {
      text: '  ',
      value: 'paid',
      sortable: false,
      align: 'center',
    },
  ];

  private service: LoanService = new LoanService();
  private reportService: BillingReportService = new BillingReportService();

  async markParcelsPaid(loanId: string, parcelId: string) {
    const payload: JsonPatchPayload = [];
    payload.push({
      op: JsonPatchOperator.replace,
      path: `/parcels/${parcelId}/paid`,
      value: true,
    });
    await this.service.updateLoan(loanId, payload);
    // parse each loan and parcel and mark billed.
    // this.parcels update when billed to today if when_billed empty.
  }

  // Computed
  get dataItemsBillingReports(): any[] {
    this.unpayDialog = {};
    this.menu = {};
    return this.processedBillingReports;
  }

  get billingPanelHeaders(): any[] {
    const headers = [].concat(this.defaultBillingPanelHeaders);

    headers.push(
      ...this.optionals.map((optional) => ({
        text: changeCase.capitalCase(optional),
        value: optional,
        sortable: false,
      })),
    );

    return headers;
  }

  async updateOptions(options: any) {
    this.isLoading = true;
    this.currentOptions = options;
    this.getReports();
  }

  async searchFieldUpdated(search: string) {
    let searchAPIBool = true;
    if (this.currentSearch.length === 0 && search.length === 1) {
      searchAPIBool = false;
    }
    if (this.currentSearch.length === 1 && search.length === 0) {
      searchAPIBool = false;
    }
    this.currentSearch = search;
    if (searchAPIBool) {
      this.currentOptions.page = 1;
      this.getReports();
    }
  }

  async updateSearchBy() {
    this.currentOptions.page = 1;
    this.getReports();
  }

  async getReports() {
    this.isLoading = true;
    if (!this.currentDebounce) {
      this.currentDebounce = debounce(this.searchForReports, 500);
    }

    this.currentDebounce();
  }

  async searchForReports() {
    const params: any = {
      limit: this.currentOptions.itemsPerPage,
      offset: (this.currentOptions.page - 1) * this.currentOptions.itemsPerPage,
      order_by: 'invoiceNumber',
      order_by_desc: false,
    };

    if (this.currentSearch && this.currentSearch.length > 1) {
      params.searchField = this.currentSearchBy.value;
      params.searchValue = this.currentSearch;
    }

    if (this.currentOptions.sortBy.length > 0) {
      [params.order_by] = this.currentOptions.sortBy;
      [params.order_by_desc] = this.currentOptions.sortDesc;
    }

    try {
      await this.getAllBillingReports(params);
    } catch (e) {
      console.log(e);
    }
    this.isLoading = false;
  }

  async updateBillSent(billSent: boolean, item: any) {
    const payload: JsonPatchPayload = [];

    payload.push({
      op: JsonPatchOperator.replace,
      path: '/bill_issued_by',
      value: billSent ? this.user.id : null,
    });
    payload.push({
      op: JsonPatchOperator.replace,
      path: '/bill_sent_date',
      value: billSent ? DateTime.local().toJSDate() : null,
    });

    await this.reportService.updateReport(item.billingReportId, payload);
    this.getAllBillingReports();
  }

  updateReport(report: any, checkNumber: string, checkDate: string, amount: number) {
    if (!(this.$refs.payForm as any).validate()) {
      return true;
    }
    const payload: JsonPatchPayload = [];
    const currentReport: BillingReport = this.billingReports.find((rep) => rep.billingReportsId === report.billingReportId);
    const newAmountPaid = (currentReport.amountPaid || []);
    newAmountPaid.push({
      checkNumber,
      checkDate,
      amount,
    });
    const totalAmountPaid = newAmountPaid.reduce((totalPaid: number, check: any) => {
      totalPaid += parseFloat(check.amount);
      return totalPaid;
    }, 0);

    if (totalAmountPaid === currentReport.totalCost) {
      payload.push({
        op: JsonPatchOperator.replace,
        path: '/paid',
        value: true,
      });
    }

    payload.push({
      op: JsonPatchOperator.replace,
      path: '/amount_paid',
      value: JSON.stringify(newAmountPaid),
    });

    this.check = '';
    this.checkDate = '';
    this.amount = '';

    this.updatePayment(report.billingReportId, payload);
    return false;
  }

  async updatePayment(billingReportId: string, payload: JsonPatchPayload) {
    const updatedReport = await this.reportService.updateReport(billingReportId, payload);
    await this.getAllBillingReports();
  }

  async downloadReport(billingReportsId: string) {
    const selectedReport = await this.reportService.getReportById(billingReportsId.toLowerCase());
    const fileToDownload = selectedReport.files.filter((obj) => obj.name.toUpperCase() === selectedReport.reportLink.toUpperCase())
    if (fileToDownload[0]) {
      fileToDownload[0].url = fileToDownload[0].url.replace(billingReportsId.toUpperCase(), billingReportsId.toLowerCase())
    }
    axios({
      url: fileToDownload[0].url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileToDownload[0].name);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  }

  deleteReport(report: any) {
    this.isUpdating[String(report.invoiceNumber)] = true;
    const reportsPayload: JsonPatchPayload = [];
    const loansPayload: JsonPatchPayload = [];
    const currentReport: BillingReport = this.billingReports.find((rep) => rep.billingReportsId === report.billingReportId);

    reportsPayload.push({
      op: JsonPatchOperator.replace,
      path: '/paid',
      value: false,
    });
    reportsPayload.push({
      op: JsonPatchOperator.replace,
      path: '/active',
      value: false,
    });

    Object.keys(currentReport.parcelIds).forEach((key : any) => {
      currentReport.parcelIds[key].forEach((parcel: any) => {
        loansPayload.push({
          op: JsonPatchOperator.replace,
          path: `/${key}/parcels/${parcel}/when_billed`,
          value: null,
        });
        loansPayload.push({
          op: JsonPatchOperator.replace,
          path: `/${key}/parcels/${parcel}/billed_report`,
          value: null,
        });
      });
    });

    this.updateBackend(report.billingReportId, reportsPayload, loansPayload, String(report.invoiceNumber));
  }

  async updateBackend(billingReportId: string, reportsPayload: JsonPatchPayload, loansPayload: JsonPatchPayload, invoiceNumber: string) {
    await this.reportService.updateReport(billingReportId, reportsPayload);

    const batchPromises = [];
    for (let i = 0; i < loansPayload.length; i += 50) {
      batchPromises.push(
        this.service.batchPatchLoans(loansPayload.slice(i, i + 50)),
      );
    }
    await Promise.all(batchPromises);
    this.isUpdating[invoiceNumber] = false;
    await this.getAllBillingReports();
  }

  unpayParcels(report: any) {
    this.isUpdating[String(report.invoiceNumber)] = true;
    const reportsPayload: JsonPatchPayload = [];
    const loansPayload: JsonPatchPayload = [];
    const currentReport: BillingReport = this.billingReports.find((rep) => rep.billingReportsId === report.billingReportId);

    reportsPayload.push({
      op: JsonPatchOperator.replace,
      path: '/paid',
      value: false,
    });
    reportsPayload.push({
      op: JsonPatchOperator.replace,
      path: '/amount_paid',
      value: JSON.stringify([]),
    });

    Object.keys(currentReport.parcelIds).forEach((key : string) => {
      currentReport.parcelIds[key].forEach((parcel: any) => {
        loansPayload.push({
          op: JsonPatchOperator.replace,
          path: `/${key}/parcels/${parcel}/paid`,
          value: false,
        });
      });
    });

    this.updateBackend(report.billingReportId, reportsPayload, loansPayload, String(report.invoiceNumber));
  }

  async created() {
    await this.getAllBillingReports();
  }

  async getAllBillingReports(params?: any) {
    const reports = await this.reportService.getBillingReports(params);
    this.billingReports = reports.reports;
    this.processBillingReports();
  }

  processBillingReports() {
    this.processedBillingReports = this.billingReports.map((report: BillingReport) => ({
      billingReportId: report.billingReportsId,
      reportName: report.reportName,
      lender: `${report.lender.lender_number} - ${report.lender.lender_name}`,
      invoiceNumber: report.invoiceNumber,
      billIssuedBy: report.billIssuedBy ? `${report.billIssuedBy.given_name} ${report.billIssuedBy.family_name}` : '',
      parcelsList: report.parcelIds ? sumBy(Object.values(report.parcelIds), 'length') : 2,
      when_billed: DateTime.fromISO(report.timestamp).toFormat('MM/dd/yyyy'),
      billSent: report.billIssuedBy != null,
      paid: report.paid,
      billSentDate: report.billSentDate ? DateTime.fromISO(report.billSentDate).toFormat('MM/dd/yyyy') : null,
      totalCost: `$${parseFloat(report.totalCost).toFixed(2)}`,
      amountPaid: `$${((report.amountPaid || []).reduce((total: number, check: any) => (total + parseFloat(check.amount)), 0)).toFixed(2)}`,
      amountObject: report.amountPaid,
    }));
    this.processedBillingReports.sort((a, b) => ((a.paid > b.paid) ? 1 : -1));
  }
}
