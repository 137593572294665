






























































































































































































































































import {
  Component, Prop, Vue, Emit, Watch, Mixins,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';

import Lender from '@/entities/Lender';
import Loan from '@/entities/Loan';
import Parcel from '@/entities/Parcel';
import LenderService from '@/services/lenders';
import LoanService from '@/services/loans';
import DateField from '@/components/inputs/dates/DateField.vue';

import { LineItem } from '@/views/billing/ReportSummary.vue';
import SimpleChipAutocomplete from '@/components/inputs/SimpleChipAutocomplete.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import CurrencyFormatter from '@/mixins/CurrencyFormatter.vue';

@Component({
  name: 'billing-data-report-input',
  components: {
    DateField,
    DatePicker,
    SimpleChipAutocomplete,
  },
})
export default class BillingDataReportInput extends Mixins(CurrencyFormatter) {
  private selectedLenders: string[] = [];

  private selectedLoans: string[] = [];

  private useRelatedLenders: boolean = false;
  private selectAllLenders: boolean = false;

  private fieldOptions = [
    { text: 'Lender #', value: 'lenderNumber' },
    { text: 'Contract ID', value: 'contractId' },
    { text: 'Billed For Date', value: 'billedForDate' },
    { text: 'Branch #', value: 'branchNumber' },
  ];

  private selectedFields: string[] = [];

  private footnote: boolean = false;

  private footnoteText: string = '';

  private loanTaxType: 'E' | 'N' | 'EN' | 'All' = 'All';

  private parcelTaxType: 'E' | 'N' | 'EN' | 'All' = 'All';

  private fromDate: string = '';

  private toDate: string = '';

  private loanIdTag: string = '';

  private parcelIdTag: string = '';

  private title: string = '';

  private loanNumbersString: string = '';

  private loanNumbers: boolean = false;

  private pageNumberToggle: boolean = false;

  private displayLoanType: boolean = false;

  private showAdjustmentError: boolean = false;

  private isValidDateTo: boolean = false;

  private isValidDateFrom: boolean = false;

  private printedDate: boolean = false;

  private lineItems: LineItem[] = [];

  private loans: Loan[] = [];

  private lenders: any[] = [];

  private lendersSearchText: string = null;

  private optionalSearchInput: string = null;

  private loanSearchText: string = null;

  private lineItemToAdd: LineItem = {};

  private lenderService: LenderService = new LenderService();
  private loanService: LoanService = new LoanService();

  private isLoading: Boolean = false;
  currentLimit = 500;
  currentOffset = 0;
  protected dataRetrievalToken: { cancel: Function } = null;
  protected pageSizes: number[] = [500];

  private rules: any = {
    required: (value: any) => !!value || 'Required.',
    validDate: (value: any) => ((DateTime.fromFormat(value || '', 'MM/dd/yyyy').isValid) && DateTime.fromFormat(value || '', 'MM/dd/yyyy').year >= 1582) || 'Must be a valid MM/DD/YYYY date',
  };

  // Computed
  get canFetchData(): boolean {
    return (this.selectedLenders.length !== 0 && this.title.length > 0 && this.fromDate && this.fromDate.length > 0 && this.toDate && this.toDate.length > 0 && this.isValidDateFrom && this.isValidDateTo);
  }

  get loanNumbersTextIsntBlank(): boolean {
    return Boolean(this.loanNumbersString) && this.loanNumbersString !== '';
  }

  created() {
    this.getAllLenders();
  }

  @Emit('emitToggle')
  emitToggleBillingForm() {}

  @Watch('selectedLenders')
  async autopickLender(val: Lender[], oldVal: Lender[]) {
    if (val.length === 1) {
      this.lineItemToAdd.lender = this.lenders.find((lender) => lender.id === val[0]);
    }
  }

  @Watch('selectAllLenders')
  async selectAllLendersChanged(val: Lender[], oldVal: Lender[]) {
    this.dataIsChanged(this.selectedLenders);
  }

  protected cancel() {
    if (this.dataRetrievalToken) {
      this.dataRetrievalToken.cancel();
      this.dataRetrievalToken = null;
    }
  }

  async getLoans(val: any): Promise<void> {
    this.isLoading = true;
    await this.getAllData(val);
    this.isLoading = false;
    return null;
  }

  dataIsChanged(val: any) {
    this.loans = [];
    this.currentOffset = 0;
    this.cancel();
  }

  async runQuery() {
    if (this.canFetchData) {
      await this.getLoans(this.selectedLenders)
    }
  }

  getParams(val: any): any {
    const params: any = {
      advanced_search: {
        lender_numbers: this.selectAllLenders ? this.lenders.map((l) => l.id) : Object.values(val),
        parcel_date_added_between: [this.fromDate, this.toDate],
        limit_final_query_flag: true,
      },
    };
    if (this.loanNumbersTextIsntBlank) {
      params.advanced_search.loan_numbers = this.loanNumbersString.split(',').map((s) => s.trim());
    }

    return params;
  }

  protected getRows(params: any, limit: number, offset: number): Promise<any> {
    params.limit = limit;
    params.offset = offset;
    return this.loanService.getAllLoans(params);
  }

  // Computed
  get reportLenders(): Lender[] {
    const lenders: Lender[] = this.selectedLenders.reduce((allLenders: Lender[], lenderId: string) => {
      const lender: Lender = this.lenders.find((candidateLender) => candidateLender.id === lenderId);
      allLenders.push(lender);

      return allLenders;
    }, []);

    return lenders;
  }

  get reportLoans(): Loan[] {
    let reportLoans: Loan[] = [];

    if (this.selectedLoans.length > 0) {
      reportLoans = cloneDeep(this.selectedLoans).reduce<Loan[]>((allLoans: Loan[], loanNumber: string) => {
        const loan: Loan = this.loans.find((candidateLoan) => candidateLoan.loanNumber === loanNumber);
        allLoans.push(loan);

        return allLoans;
      }, []);
    } else {
      reportLoans = cloneDeep(this.loans);
    }

    if (this.loanIdTag) {
      reportLoans = reportLoans.filter((loan) => (loan.idFlag != null && loan.idFlag.toUpperCase() === this.loanIdTag.toUpperCase()));
    }

    if (this.loanTaxType !== 'All') {
      reportLoans = reportLoans.filter((loan) => loan.loanType === this.loanTaxType);
    }

    let loansWithNoParcels: any[] = [];

    if (!this.parcelIdTag) {
      loansWithNoParcels = reportLoans.filter((loan) => loan.parcels && loan.parcels.length === 0);
    }

    reportLoans.forEach((loan: Loan) => {
      let allParcels = loan.parcels;

      // Filter through dates if they were defined
      // if (this.fromDate.length > 8 && this.toDate.length > 9) {
      //   const convertedFromDate: Date = new Date(this.fromDate);
      //   const convertedToDate: Date = new Date(this.toDate);
      //   allParcels = allParcels.filter((parcel: Parcel) => {
      //     const parcelDate: Date = new Date(parcel.dateAdded);

      //     return parcelDate >= convertedFromDate && parcelDate <= convertedToDate;
      //   });
      // }

      if (this.parcelIdTag) {
        allParcels = allParcels.filter((parcel) => parcel.idTag != null && parcel.idTag.toUpperCase() === this.parcelIdTag.toUpperCase());
      }

      if (this.parcelTaxType !== 'All') {
        allParcels = allParcels.filter((parcel) => parcel.parcelType === this.parcelTaxType);
      }

      loan.parcels = allParcels;
    });

    reportLoans = reportLoans.filter((loan) => loan.parcels && loan.parcels.length > 0);

    return reportLoans.concat(loansWithNoParcels);
  }

  get reportParcels(): Parcel[] {
    const parcels = this.reportLoans.reduce((allParcels: Parcel[], loan: Loan) => {
      allParcels.push(...loan.parcels);
      return allParcels;
    }, []);

    return parcels;
  }

  get validLenders(): Lender[] {
    return this.selectedLenders.reduce((selectedLenderObjects, lenderId) => {
      selectedLenderObjects.push(this.lenders.find((lender) => lender.id === lenderId));
      return selectedLenderObjects;
    }, []);
  }

  validateAndRun() {
    const isBillingFormValid = (this.$refs.billingForm as any).validate();
    const isParcelsFormValid = (this.$refs.parcelsForm as any).validate();
    if (!isBillingFormValid || !isParcelsFormValid) {
      return;
    }
    if (((this.lineItemToAdd.value !== undefined && this.lineItemToAdd.value > 0)
          || (this.lineItemToAdd.description !== undefined && this.lineItemToAdd.description.length > 0)
          || (this.lineItemToAdd.date !== undefined && this.lineItemToAdd.date.length > 0))) {
      this.showAdjustmentError = true;
      return;
    }
    this.runReport();
  }

  @Emit('run')
  runReport() {
    return {
      config: {
        optionals: this.selectedFields,
        name: this.title,
        startDate: this.fromDate ? new Date(this.fromDate) : DateTime.fromMillis(0).toJSDate(),
        endDate: this.toDate ? new Date(this.toDate) : DateTime.local().toJSDate(),
        lineItems: this.lineItems,
        displayLoanType: this.displayLoanType,
        pageNumberToggle: this.pageNumberToggle,
        footnote: this.footnote,
        footnoteText: this.footnoteText,
        printedDate: this.printedDate,
      },
      lenders: this.selectAllLenders ? this.lenders : this.reportLenders,
      loans: this.reportLoans,
    };
  }

  async getAllLenders() {
    const params = {
      order_by: 'lenderNumber',
      order_desc: false,
    };

    try {
      const lenderSummary = await this.lenderService.getAllLenders(params);
      this.lenders = lenderSummary.lenders;
    } catch (e) {
      console.log(e);
      this.lenders = [];
    }
  }

  determineLenderName(item: Lender): string {
    return `${item.name} (${item.id})`;
  }

  determineLoanName(item: Loan): string {
    return `${item.borrowerName || item.borrowerName2 || item.companyName} (${item.loanNumber})`;
  }

  addItemToList() {
    if (!(this.$refs.adjustmentForm as any).validate()) {
      return;
    }

    this.lineItemToAdd.value = parseFloat((this.lineItemToAdd.value as string).replace('$', ''));
    // this.lineItemToAdd.date = this.lineItemToAdd.date ? DateTime.fromISO(this.lineItemToAdd.date).toJSDate() : null;
    this.lineItems.push({ ...this.lineItemToAdd });
    this.lineItemToAdd = {};
  }

  async getAllData(val: any) {
    this.loans = [];
    const params = this.getParams(val);

    this.isLoading = true;

    const retrieveAllData = new Promise<void>((resolve, reject) => {
      const that = this;
      let isCancelled = false;
      this.dataRetrievalToken = {
        cancel: () => {
          isCancelled = true;
          resolve();
        },
      };

      function recursiveRetrieval(limit: number, offset: number): Promise<void> {
        if (that.pageSizes.length === 0) {
          throw new Error('You must assign at least one page size for background data retrieval.');
        }

        let page = 0;
        return that.getRows(params, limit, offset)
          .then((data) => {
            const newLoans = data.results;
            if (newLoans.length === 0 || isCancelled) {
              return Promise.resolve();
            }

            that.loans = that.loans.concat(newLoans);

            page += 1;
            const pageIndex = that.pageSizes.length <= page ? that.pageSizes.length - 1 : page;
            const newLimit = that.pageSizes[pageIndex];

            return recursiveRetrieval(newLimit, offset + limit);
          })
          .catch((e: Error) => {
            console.log(e);
            return Promise.reject();
          });
      }

      recursiveRetrieval(that.pageSizes[0], 0)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });

    retrieveAllData.finally(() => {
      this.isLoading = false;
    });

    return retrieveAllData;
  }
}
