import Axios, { AxiosResponse } from 'axios';

import IServiceBillingReport from '@/services/api/models/IServiceBillingReport';
import { JsonPatchPayload } from '@/helpers/vuelidateToPatch';

interface ServicesResponse {
  count: any,
  reports: IServiceBillingReport[],
}

export default {
  async getBillingReports(params: any): Promise<ServicesResponse> {
    const response = await Axios.get<ServicesResponse>('/billing-reports', {
      params,
    });

    return response.data;
  },

  async getReportById(id: string): Promise<IServiceBillingReport> {
    const response = await Axios.get<IServiceBillingReport>(`/billing-reports/${id}`);
    return response.data;
  },

  async updateReport(id: string, payload: JsonPatchPayload): Promise<void> {
    console.log('api service patch');
    await Axios.patch<void>(`/billing-reports/${id}`, payload);
  },

  async createReport(payload: any): Promise<IServiceBillingReport> {
    const response = await Axios.post<IServiceBillingReport, AxiosResponse<IServiceBillingReport>>('/billing-reports', payload);
    return response.data;
  },

  async getLatestInvoice(): Promise<IServiceBillingReport> {
    const response = await Axios.get<IServiceBillingReport>('/billing-reports/latest-invoice');
    return response.data;
  },

  async uploadFile(id: string, formData: FormData): Promise<void> {
    await Axios.post<void, AxiosResponse<void>>(`/billing-reports/${id}/files`, formData);
  },
};
