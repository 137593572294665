

































import {
  Component, Prop, Emit, Vue,
} from 'vue-property-decorator';

@Component({
  name: 'simple-chip-autocomplete',
})
export default class SimpleChipAutocomplete extends Vue {
  @Prop({
    type: Array,
    default: () => [] as string[],
  }) readonly value!: string[];

  @Prop({
    type: [String, Function],
    default: 'text',
  }) readonly chipValue!: string | (() => string);

  private searchInput: string = '';

  @Emit('input')
  update(newValue: string[]) {
    return newValue;
  }

  remove(item: string) {
    const newValue = [...this.value];
    newValue.splice(
      this.value.findIndex((value) => value === item),
      1,
    );

    this.update(newValue);
  }
}
