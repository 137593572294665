import BillingReportsApiService from '@/services/api/BillingReportsApiService';
import BillingReport from '@/entities/BillingReport';
import { JsonPatchPayload } from '@/helpers/vuelidateToPatch';

interface ReportCollection {
  count: number,
  reports: BillingReport[],
}

interface AdvancedSearch {
  invoice_numbers?: string[],
}

interface ReportSearchParams {
  limit: number,
  offset: number,
  order_by: string,
  order_by_desc: boolean,
  search_field: string,
  search_value: string,
  advanced_search: AdvancedSearch,
}

class BillingReportService {
  async getBillingReports(params: ReportSearchParams): Promise<ReportCollection> {
    const stringifiedParams: any = { ...params };
    stringifiedParams.advanced_search = JSON.stringify(stringifiedParams.advanced_search);

    const response = await BillingReportsApiService.getBillingReports(stringifiedParams);

    return {
      count: parseInt(response.count, 10),
      reports: response.reports.map((serviceReports) => new BillingReport(serviceReports)),
    };
  }

  async getReportById(id: string): Promise<BillingReport> {
    const response = await BillingReportsApiService.getReportById(id);

    return new BillingReport(response);
  }

  async updateReport(id: string, payload: JsonPatchPayload): Promise<void> {
    await BillingReportsApiService.updateReport(id, payload);
  }

  async createReport(payload: any): Promise<BillingReport> {
    const response = await BillingReportsApiService.createReport(payload);
    return new BillingReport(response);
  }

  async getLatestInvoice(): Promise<BillingReport> {
    // const response = await BillingReportsApiService.getLatestInvoice();
    return new BillingReport(await BillingReportsApiService.getLatestInvoice());
  }

  async uploadFile(id: string, file: File): Promise<void> {
    const formData: FormData = new FormData();
    formData.set('file', file);
    await BillingReportsApiService.uploadFile(id, formData);
  }
}

export { BillingReportService as default };
