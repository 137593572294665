







































































import {
  Component, Prop, Vue, Watch, Emit,
} from 'vue-property-decorator';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import jsPDF from 'jspdf';
import Papa from 'papaparse';
import autoTable from 'jspdf-autotable';
import { JsonPatchOperator, JsonPatchPayload } from '@/helpers/vuelidateToPatch';
import XLSX from 'xlsx';
import Axios from 'axios'

import BillingReport from '@/views/billing/BillingReport.vue';

import Lender from '@/entities/Lender';
import Loan from '@/entities/Loan';
import Address from '@/entities/Address';
import LoanService from '@/services/loans';
import BillingReportService from '@/services/billingReports';
import Parcel from '@/entities/Parcel';

import capitalTaxLogo from '@/assets/logo.jpg';
import buildOnePageWorkBook from '@/helpers/exports/xls/ExcelUtil';
import { cloneDeep, sortBy } from 'lodash';

export interface LineItem {
  lender?: Lender;
  description?: string;
  value?: number | string;
  date?: string;
}

export interface Report {
  loanNumber?: string;
  type?: string;
  parcelCount?: number;
  billForCount?: number;
  unitsCharged?: number;
  totalCost?: number;
  name?: string;
}

export interface PdfReport {
  [key: string]: any

  loanNumber?: string;
  type?: string;
  parcelCount?: string;
  billForCount?: string;
  unitsCharged?: string;
  totalCost?: string;
  name?: string;
}

export interface LoanReport {
  lender?: Lender;
  report?: Report[];
  lineItems?: LineItem[];
}

interface ReportConfig {
  name: string;
  startDate: Date;
  endDate: Date;
  lineItems?: LineItem[];
  footnote: boolean;
  footnoteText?: string;
  pageNumberToggle: boolean;
  displayLoanType: boolean;
  printedDate?: boolean;
}

@Component({
  components: {
    BillingReport,
  },
})
export default class ReportSummary extends Vue {
  @Prop({
    type: Array,
    default: (): Lender[] => [],
  })
  private readonly lenders!: Lender[];

  @Prop({
    type: Array,
    default: (): Loan[] => [],
  })
  private readonly loans!: (Loan & { coveredParcels: Parcel[] })[];

  @Prop({
    type: Object,
    default: (): ReportConfig => ({
      name: 'The Report',
      startDate: DateTime.local().toJSDate(),
      endDate: DateTime.local()
        .plus({ days: 1 })
        .toJSDate(),
      lineItems: [] as LineItem[],
      footnote: false,
      pageNumberToggle: false,
      displayLoanType: false,
    }),
  })
  private readonly config!: ReportConfig;

  @Prop({
    type: Array,
    default: (): string[] => [],
  })
  private readonly optionals!: string[];

  private defaultLoanHeaders = [
    {
      text: 'Loan Number', value: 'loanNumber', sortable: true, cellWidth: 32,
    },
    {
      text: 'E/N', value: 'type', sortable: true, cellWidth: 13,
    },
    {
      text: 'Parcel Count', value: 'parcelCount', sortable: true, cellWidth: 15,
    },
    {
      text: 'Parcels Billed', value: 'billForCount', sortable: true, cellWidth: 16,
    },
    {
      text: 'Units Billed', value: 'unitsCharged', sortable: true, cellWidth: 20,
    },
    {
      text: 'Name/Company Name', value: 'name', sortable: true, cellWidth: 104,
    },
  ];

  private adjustmentHeaders: any[] = [
    { text: 'Description', value: 'description', sortable: true },
    { text: 'Amount', value: 'value', sortable: true },
    { text: 'Date', value: 'date', sortable: true },
  ];

  private totalHeaders: any[] = [
    { text: 'Subtotal', value: 'description', sortable: true },
    { text: 'Amount', value: 'value', sortable: true },
  ];

  private fileTypes: any[] = [
    {
      name: 'PDF',
      export: () => {
        this.downloadPdf((this.config && this.config.name) || 'BillingReport');
      },
    },
    {
      name: 'XLSX',
      export: () => {
        this.downloadXLSX((this.config && this.config.name) || 'BillingReport');
      },
    },
  ];

  private unbilledParcels: any = {};
  private service: LoanService = new LoanService();
  private reportService: BillingReportService = new BillingReportService();

  private selectedReport?: any = null;

  // Watchers
  @Watch('loanReports', { immediate: true })
  onReportsChanged(val: any[]) {
    if (!this.selectedReport) {
      [this.selectedReport] = val;
    }
  }

  // Computed
  get totalLoansBilled(): number {
    return this.selectedReport.report.length || 0;
  }

  get totalParcelsBilled(): number {
    const totalBilled = this.selectedReport.report.reduce((total: number, report: any) => {
      total += report.billForCount;
      return total;
    }, 0);

    return totalBilled || 0;
  }

  get totalUnitsBilled(): number {
    const totalParcels = this.selectedReport.report.reduce((total: number, report: any) => {
      total += report.unitsCharged;
      return total;
    }, 0);
    return totalParcels || 0;
  }

  get parcels(): Parcel[] {
    const parcels = this.loans.reduce((allParcels: Parcel[], loan: Loan) => {
      allParcels.push(...loan.parcels);
      return allParcels;
    }, []);

    return parcels;
  }

  get dateSummary(): String {
    if (!this.config.startDate || !this.config.endDate) return '';

    const startDate: String = DateTime.fromJSDate(
      this.config.startDate,
    ).toFormat('MM/dd/yyyy');
    const endDate: String = DateTime.fromJSDate(this.config.endDate).toFormat(
      'MM/dd/yyyy',
    );
    return `${startDate} - ${endDate}`;
  }

  get loanReports(): LoanReport[] {
    return this.lenders.map((lender: Lender) => ({
      lender,
      report: this.buildReport(
        this.loansByLenderNumber(lender.id),
        lender,
      ),
      lineItems: this.adjustmentReports.get(lender.id),
    }));
  }

  get adjustmentReports(): Map<string, any[]> {
    return this.parseAdjustments(this.config.lineItems);
  }

  get selectedAdjustments(): any[] {
    return this.selectedReport && this.selectedReport.lender
      ? this.adjustmentReports.get(this.selectedReport.lender.id)
      : [];
  }

  get loanHeaders(): any[] {
    const headers = [].concat(cloneDeep(this.defaultLoanHeaders));
    const optionalWidth = 18;

    headers.push(
      ...this.optionals.map((optional) => ({
        text: capitalCase(optional),
        value: optional,
        sortable: false,
        cellWidth: optionalWidth,
      })),
    );

    const nameColumn = headers.find((header) => header.value === 'name');
    if (nameColumn && this.optionals.length > 0) {
      nameColumn.cellWidth -= (this.optionals.length * optionalWidth);
    }

    return headers;
  }

  get loanSummaryHeaders(): any[] {
    return [
      { text: 'Customer ID', value: 'lenderNumber', sortable: true },
      { text: 'Description', value: 'description', sortable: true },
      { text: 'Units', value: 'units', sortable: true },
      { text: 'Unit Price', value: 'unitPrice', sortable: true },
      { text: 'Subtotal', value: 'subtotal', sortable: true },
    ];
  }

  calculateTotal(entries: any[], key: string = 'value'): number {
    return entries.reduce((total: number, entry: any) => total + entry[key], 0);
  }

  loansByLenderNumber(lenderNumber: string) {
    return this.loans.filter((loan) => loan.lenderNumber === lenderNumber);
  }

  parseAdjustments(lineItems: LineItem[]): Map<string, any[]> {
    return lineItems.reduce((adjustmentMap: Map<string, any[]>, lineItem: LineItem) => {
      if (
        adjustmentMap.has(lineItem.lender.id)
      ) {
        adjustmentMap.get(lineItem.lender.id).push(lineItem);
      } else {
        adjustmentMap.set(lineItem.lender.id, [lineItem]);
      }

      return adjustmentMap;
    }, new Map());
  }

  // Returns number of parcels billed already from a list of parcels.
  getPrevBilledParcels(parcels: Parcel[]) {
    const newParcelTemp: Parcel[] = [];
    let count: number = 0;
    parcels.forEach((value) => {
      if (value.whenBilled) {
        count += 1;
      } else {
        newParcelTemp.push(value);
      }
    });
    // this.markParcelsBilled(this.unbilledParcels);
    return { count, newParcels: newParcelTemp };
  }

  buildReport(loans: ({ [index: string]: any } & Loan & { coveredParcels: Parcel[] })[], lender: Lender) {
    lender.forEvery = lender.forEvery || 1;
    lender.billed = lender.billed || 1;
    this.unbilledParcels[lender.lenderId] = [];
    const loansFinal = loans.map((loan) => {
      const unitsToCharge = Math.ceil(loan.parcels.length / lender.forEvery);
      const adjustedUnitsPerSpecialParcel: number = Math.ceil(loan.parcels.length / lender.forEvery) * lender.billed;

      const coveredParcels = this.getPrevBilledParcels(loan.parcels);
      const countParcelsBilledPrev = coveredParcels.count;
      loan.coveredParcels = coveredParcels.newParcels;
      this.unbilledParcels[lender.lenderId].push(...coveredParcels.newParcels);
      let countNewParcel: number = loan.parcels.length - countParcelsBilledPrev;

      let numUnitsForPrevBilled: number = 0;
      let incompleteSetBilledPrev: number = 0;
      incompleteSetBilledPrev = countParcelsBilledPrev % lender.forEvery;
      numUnitsForPrevBilled
        += countParcelsBilledPrev > lender.forEvery
          ? Math.floor(countParcelsBilledPrev / lender.forEvery) * lender.billed
          : 0;
      const numUnitsBilledForIncompleteSet = incompleteSetBilledPrev <= lender.billed
        ? incompleteSetBilledPrev
        : lender.billed;
      numUnitsForPrevBilled += numUnitsBilledForIncompleteSet;

      const noReqdToCompleteSet = lender.forEvery - incompleteSetBilledPrev;
      incompleteSetBilledPrev += countNewParcel % (noReqdToCompleteSet + 1);
      countNewParcel -= countNewParcel % (noReqdToCompleteSet + 1);

      const updatedNoUnitsForIncompleteSet = incompleteSetBilledPrev <= lender.billed
        ? incompleteSetBilledPrev
        : lender.billed;
      let numUnitsNew: number = 0;

      numUnitsNew
        += updatedNoUnitsForIncompleteSet - numUnitsBilledForIncompleteSet;

      numUnitsNew += Math.floor(countNewParcel / lender.forEvery) * lender.billed;
      numUnitsNew
        += countNewParcel % lender.forEvery <= lender.billed
          ? countNewParcel % lender.forEvery
          : lender.billed;

      const defaultSummary = {
        lenderNumber: loan.lenderNumber,
        loanNumber: loan.loanNumber,
        branchNumber: loan.branchNumber,
        type: loan.loanType && loan.loanType.toString(),
        parcelCount: loan.parcels.length,
        billForCount: (loan.coveredParcels || []).length,
        unitsCharged: numUnitsNew,
        totalCost: this.calculateParcelCost(loan, numUnitsNew),
        name: (loan.companyName && loan.borrowerName) ? (`${loan.companyName} / ${loan.borrowerName}`) : (loan.companyName || loan.borrowerName),
      };

      Object.assign(
        defaultSummary,
        this.optionals.reduce((obj, optional) => {
          obj[optional] = (loan[optional] && loan[optional].toString()) || '';
          return obj;
        }, {} as { [index: string] : any }),
      );

      return defaultSummary;
    });
    return loansFinal.filter((l) => l.billForCount > 0 || l.parcelCount === 0);
  }

  calculateParcelCost(loan: Loan, unitsToCharge: number): number {
    const { serviceFee } = this.lenders.find((lender) => lender.id === loan.lenderNumber)
    return unitsToCharge * serviceFee;
  }

  buildAggregate(summaries: any[]): any {
    return summaries.reduce(
      (aggregate: any, summary: any) => {
        aggregate.parcelCount += summary.parcelCount ? summary.parcelCount : 0;
        aggregate.billForCount += summary.billForCount
          ? summary.billForCount
          : 0;
        aggregate.totalCost += summary.totalCost ? summary.totalCost : 0;
        return aggregate;
      },
      { parcelCount: 0, billForCount: 0, totalCost: 0 },
    );
  }

  generateTotals(reports: Report[] = [], adjustments: LineItem[] = []): any[] {
    const totals = [];

    totals.push({
      description: 'Loans',
      value: this.calculateTotal(reports, 'totalCost'),
    });

    totals.push({
      description: 'Adjustment Items',
      value: this.calculateTotal(adjustments),
    });

    totals.push({
      description: 'Grand Total',
      value: this.calculateTotal(totals),
    });

    return totals.map((lineItem: LineItem) => ({
      description: lineItem.description,
      value: `$${(lineItem.value as number).toFixed(2)}`,
    }));
  }

  async buildLenderPagesOnServer(
    reportPdf: any,
    lender: Lender,
    reports: Report[] = [],
    adjustments: LineItem[] = [],
    invoiceNumber: string = 'Not Issued',
  ) {
    const formData = new FormData()
    formData.append('reportPdf', JSON.stringify(reportPdf));
    formData.append('lender', JSON.stringify(lender));
    formData.append('reports', JSON.stringify(reports));
    formData.append('adjustments', JSON.stringify(adjustments));
    formData.append('config', JSON.stringify(this.config));
    formData.append('loanSummaryHeaders', JSON.stringify(this.loanSummaryHeaders));
    formData.append('totalHeaders', JSON.stringify(this.totalHeaders));
    formData.append('adjustmentHeaders', JSON.stringify(this.adjustmentHeaders));
    formData.append('loanHeaders', JSON.stringify(this.loanHeaders));
    formData.append('optionals', JSON.stringify(this.optionals));
    formData.append('dateSummary', this.dateSummary.toString());
    formData.append('invoiceNumber', invoiceNumber);

    const res = await Axios.post('/pdf/billing-report', formData);
    return window.open(res.data.link, '_blank');
  }

  buildLenderPagesLocally(
    reportPdf: jsPDF,
    lender: Lender,
    reports: Report[] = [],
    adjustments: LineItem[] = [],
    invoiceNumber: string = 'Not Issued',
  ) {
    const pdfTableMargin: number = 15;

    // Start Invoice report
    const img = document.createElement('img');
    img.src = capitalTaxLogo;

    // function to add pageNumber
    let pageNumber: number = 1;
    const footerTextFinal: string = this.config.footnoteText !== '' ? this.config.footnoteText : 'Make all checks payable to: Capital Real Estate Tax Services';

    function displayPageNumber() {
      reportPdf.setFontSize(12);
      reportPdf.text(pageNumber.toString(), 200, 267, { align: 'right' }); // print number bottom right
      pageNumber += 1;
    }

    function footerText() {
      reportPdf.setLineWidth(2.0);
      reportPdf.line(10, 260, 200, 260);
      reportPdf.setFontStyle('normal');
      reportPdf.setFontSize(12);
      reportPdf.text(`${footerTextFinal}`, 105, 267, { align: 'center' });
    }

    // header
    reportPdf.addImage(img, 'PNG', 15, 10, 49, 30);
    reportPdf.text('INVOICE', 190, 20, { align: 'right' });
    reportPdf.setLineWidth(2.0);
    reportPdf.line(10, 45, 200, 45);
    reportPdf.setFontStyle('normal');
    reportPdf.setFontSize(12);
    reportPdf.text(`INVOICE #: ${invoiceNumber}`, 200, 52, { align: 'right' });
    reportPdf.text(`${(this.config && this.config.name) || 'A Report'}`, 200, 58, { align: 'right' });
    if (this.dateSummary) {
      reportPdf.text(`${this.dateSummary}`, 200, 64, { align: 'right' });
    }
    reportPdf.text('1300 COMBERMERE DRIVE, TROY, MI 48083', 15, 50);
    reportPdf.text('TEL 800.335.3635', 15, 55);
    reportPdf.text('FAX 800.401.9179', 15, 60);
    reportPdf.text('capitaltax.us', 15, 65);
    // end header

    // footer
    if (this.config.footnote) {
      footerText();
    }
    // end footer

    // invoice From/to
    reportPdf.setFontSize(12);
    // var lenderName = lender.name; // to add c/o name
    // lenderName += lenderName;
    const address = lender.address.value;
    reportPdf.text(`BILL TO: ${lender.name.slice(0, 26)}`, 15, 80);
    reportPdf.text(`${address.address1 || ''} ${address.address2 || ''}`, 29, 85);
    reportPdf.text(`${address.city || ''} ${address.state || ''} ${address.zipCode || ''}`, 29, 90);

    reportPdf.text(`SHIP TO: ${lender.name.slice(0, 26)}`, 110, 80);
    reportPdf.text(`${address.address1 || ''} ${address.address2 || ''}`, 127, 85);
    reportPdf.text(`${address.city || ''} ${address.state || ''} ${address.zipCode || ''}`, 127, 90);

    // Loan summary table
    const totalUnitsCharged = reports.reduce((total, report) => {
      total += report.unitsCharged;
      return total;
    }, 0);

    const loanSummaryBody = [{
      lenderNumber: lender.id,
      description: 'Tax Service',
      units: totalUnitsCharged,
      unitPrice: `$${(lender.serviceFee || 1).toFixed(2)}`,
      subtotal: `$${((lender.serviceFee || 1) * totalUnitsCharged).toFixed(2)}`,
    }];

    reportPdf.setFontStyle('bold');
    reportPdf.setFontSize(14);
    reportPdf.text(lender.name || lender.id, pdfTableMargin, 105);
    reportPdf.setFontStyle('normal');
    autoTable(reportPdf, {
      columns: this.loanSummaryHeaders.map((header) => ({
        dataKey: header.value,
        header: header.text,
      })),
      body: loanSummaryBody,
      theme: 'grid',
      startY: 110,
      margin: pdfTableMargin,
      rowPageBreak: 'avoid',
    });

    // Adjustments table

    if (adjustments.length > 0) {
      reportPdf.setFontStyle('bold');
      reportPdf.setFontSize(14);
      reportPdf.text('Adjustment Items', pdfTableMargin, reportPdf.autoTable.previous.finalY + 20);
      autoTable(reportPdf, {
        columns: this.adjustmentHeaders.map((header) => ({
          dataKey: header.value,
          header: header.text,
        })),
        body: adjustments.map((lineItem: LineItem) => ({
          description: lineItem.description,
          value: `$${(lineItem.value as number).toFixed(2)}`,
          // date: lineItem.date
          // ? DateTime.fromJSDate(lineItem.date).toFormat('MM/dd/yyyy')
          // : null,
          date: lineItem.date,
        })),
        theme: 'grid',
        startY: reportPdf.autoTable.previous.finalY + 25,
        margin: pdfTableMargin,
        rowPageBreak: 'avoid',
      });
    }

    // Totals table
    reportPdf.setFontStyle('bold');
    reportPdf.setFontSize(14);
    reportPdf.text('TOTALS', pdfTableMargin, reportPdf.autoTable.previous.finalY + 20);
    autoTable(reportPdf, {
      columns: this.totalHeaders.map((header) => ({
        dataKey: header.value,
        header: header.text,
      })),
      didParseCell(data: any) {
        const rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = 'bold';
        }
      },
      body: this.generateTotals(reports, adjustments),
      theme: 'grid',
      startY: reportPdf.autoTable.previous.finalY + 25,
      margin: pdfTableMargin,
      rowPageBreak: 'avoid',
    });

    // Billing report pdf start
    reportPdf.addPage('letter', 'portrait');

    // header
    reportPdf.addImage(img, 'PNG', 15, 10, 49, 30);
    reportPdf.text('BILLING REPORT', 190, 20, { align: 'right' });
    reportPdf.setLineWidth(2.0);
    reportPdf.line(10, 45, 200, 45);
    reportPdf.setFontStyle('normal');
    reportPdf.setFontSize(12);
    reportPdf.text('1300 COMBERMERE DRIVE, TROY, MI 48083', 15, 50);
    reportPdf.text('TEL 800.335.3635 FAX 248.546.5065', 15, 55);
    reportPdf.text('WEB capitaltax.us', 15, 60);
    if (this.config.printedDate) {
      reportPdf.text(`Date Printed: ${DateTime.local().toFormat('MM/dd/yyyy')}`, 200, 58, { align: 'right' });
    }

    // end header

    reportPdf.setFontSize(16);
    reportPdf.text(`${lender.id} - ${lender.name}`, 15, 80);
    reportPdf.setFontSize(12);
    reportPdf.text(`${(this.config && this.config.name) || 'A Report'}`, 15, 90);
    reportPdf.text(`INVOICE #: ${invoiceNumber}, ${this.dateSummary}`, 200, 90, { align: 'right' });

    const tempLoanHeaders = this.loanHeaders.filter((header) => !(header.value === 'type' && !this.config.displayLoanType));

    // Loans table
    reportPdf.setFontStyle('bold');
    reportPdf.setFontSize(14);
    reportPdf.text('LOANS', pdfTableMargin, 105);
    reportPdf.setFontStyle('normal');
    autoTable(reportPdf, {
      columns: tempLoanHeaders.map((header) => ({
        dataKey: header.value,
        header: header.text,
      })),
      columnStyles: tempLoanHeaders.reduce((colStylesObj, loanHeader, index) => {
        colStylesObj[index] = { cellWidth: loanHeader.cellWidth };
        return colStylesObj;
      }, {}),
      body: sortBy(reports.map((report) => {
        const reportObject: Report = { ...report };
        const objectKeys = Object.keys(reportObject);

        return objectKeys.reduce(
          (object, key) => {
            if (key === 'totalCost') {
              object[key] = `$${(reportObject[key] || 0).toFixed(2).toString()}`
            } else if (key === 'name' || key === 'type') {
              object[key] = (reportObject[key] || '').toString();
            } else if (!this.optionals.find((optional) => optional === key)) {
              object[key] = (reportObject[key as keyof Report] || 0).toString();
            } else {
              object[key] = reportObject[key as keyof Report]
            }
            return object;
          },
          {} as PdfReport,
        );
      }), 'loanNumber'),
      theme: 'grid',
      startY: 110,
      margin: pdfTableMargin,
      didDrawPage: () => {
        if (this.config.footnote) {
          footerText();
        }

        if (this.config.pageNumberToggle) {
          displayPageNumber();
        }
      },
      rowPageBreak: 'avoid',
    });

    // Totals line
    reportPdf.setFontSize(12);
    const totalBilled = reports.reduce((total, loan) => {
      total += loan.billForCount;
      return total;
    }, 0);
    const totalParcels = reports.reduce((total, loan) => {
      total += loan.parcelCount;
      return total;
    }, 0);
    reportPdf.text(`TOTAL LOANS: ${reports.length}`, 20, reportPdf.autoTable.previous.finalY + 20);
    reportPdf.text(`TOTAL PARCELS: ${totalParcels}`, 20, reportPdf.autoTable.previous.finalY + 27);
    reportPdf.text(`TOTAL PARCELS BILLED: ${totalBilled}`, 20, reportPdf.autoTable.previous.finalY + 34);
  }

  buildLenderReportPdf(report: any): jsPDF {
    const reportPdfConfig: any = {
      orientation: 'portrait',
      unit: 'mm',
      format: 'letter',
    }
    const reportPdf: jsPDF = new jsPDF(reportPdfConfig);
    const { lender } = report;
    // this.buildLenderPagesLocally(reportPdf, lender, report.report, report.lineItems);
    this.buildLenderPagesOnServer(reportPdfConfig, lender, report.report, report.lineItems);
    return reportPdf;
  }

  downloadSeperateLenderPDF(name: string) {
    this.loanReports.forEach(async (value) => {
      if (value.report.length > 0) {
        const reportPdfConfig: any = {
          orientation: 'portrait',
          unit: 'mm',
          format: 'letter',
        }
        const reportPdf: jsPDF = new jsPDF(reportPdfConfig);
        // this.buildLenderPagesLocally(reportPdf, value.lender, value.report, value.lineItems);
        this.buildLenderPagesOnServer(reportPdfConfig, value.lender, value.report, value.lineItems);
        // reportPdf.save(`${name}_${value.lender.id}.pdf`);
      }
    });
  }

  buildAggregateReportPdf(): jsPDF {
    const reportPdfConfig: any = {
      orientation: 'portrait',
      unit: 'mm',
      format: 'letter',
    }
    const reportPdf: jsPDF = new jsPDF(reportPdfConfig);

    this.loanReports.forEach((value: any, index: number) => {
      const { lender } = value;
      // this.buildLenderPagesLocally(
      //   reportPdf,
      //   lender,
      //   value.report,
      //   value.lineItems,
      // );
      this.buildLenderPagesOnServer(
        reportPdfConfig,
        lender,
        value.report,
        value.lineItems,
      );
    });

    return reportPdf;
  }

  previewPdf() {
    // const pdf: jsPDF = this.buildLenderReportPdf(this.selectedReport);
    this.buildLenderReportPdf(this.selectedReport);
    // pdf.output('dataurlnewwindow');
    // pdf.save(`Preview_of_${this.config.name.toString()}` || 'Preview_of_Billing_Report');
  }

  downloadPdf(name: string) {
    // const pdf: jsPDF = this.buildAggregateReportPdf();
    // pdf.save(`${name}.pdf`);
    this.downloadSeperateLenderPDF(name);
  }

  reportsToCSVObject(value: LoanReport) {
    return value.report.map((report: Report) => {
      const reportObject: Report & { [index: string] : any } = { ...report };

      const finalObject: any = Object.keys(reportObject).reduce(
        (object, key) => {
          object[key] = reportObject[key] ? reportObject[key].toString() : null;
          return object;
        },
        {} as { [index: string]: any },
      );

      return finalObject;
    });
  }

  downloadCsv(name: string) {
    this.loanReports.forEach((value: LoanReport, index: number) => {
      const csv: string = Papa.unparse(this.reportsToCSVObject(value));

      this.openSaveFileDialog(csv, `${name}.csv`, 'text/csv');
    });
  }

  downloadXLSX(name: string) {
    this.loanReports.forEach((value: LoanReport, index: number) => {
      const wb = buildOnePageWorkBook(this.reportsToCSVObject(value), [{
        header: 'totalCost',
        type: 'currency',
      }]);

      XLSX.writeFile(wb, `${name}.xlsx`, {
        type: 'file',
      });
    });
  }

  openSaveFileDialog(data: any, filename: string, mimetype: string) {
    if (!data) return;

    const blob: Blob = data.constructor !== Blob
      ? new Blob([data], { type: mimetype || 'application/octet-stream' })
      : data;

    if ((navigator as any).msSaveBlob) {
      (navigator as any).msSaveBlob(blob, filename);
      return;
    }

    const lnk = document.createElement('a');
    const url = window.URL;
    let objectURL;

    if (mimetype) {
      lnk.type = mimetype;
    }

    lnk.download = filename || 'untitled';
    // eslint-disable-next-line no-multi-assign
    lnk.href = objectURL = url.createObjectURL(blob);
    lnk.dispatchEvent(new MouseEvent('click'));
    setTimeout(url.revokeObjectURL.bind(url, objectURL));
  }

  async createReport(reportName: string, lender: Lender, reports: Report[], adjustments: LineItem[], invoiceNumber: string, reportPdf: jsPDF) {
    const parcelIds: { [index: string]: any } = {};
    reports.forEach((report) => {
      const loan = this.loans.find((candidateLoan) => candidateLoan.loanNumber === report.loanNumber);
      const parcels = loan.coveredParcels.reduce((ids: String[], parcel: Parcel) => {
        ids.push(parcel.parcelId);

        return ids;
      }, []);
      parcelIds[loan.loanId] = parcels;
    });
    const totals = this.generateTotals(reports, adjustments);
    const reportFileName = `${reportName}_${invoiceNumber}.pdf`;
    const payload: any = {
      lender_id: lender.lenderId,
      report_name: reportName,
      report_link: reportFileName,
      invoice_number: invoiceNumber,
      total_cost: totals[2].value.slice(1),
      parcel_ids: parcelIds,
    };

    const newReport = await this.reportService.createReport(payload);
    const file = new File([reportPdf.output('blob')], reportFileName, { type: 'application/pdf' })
    await this.reportService.uploadFile(newReport.billingReportsId, file);
  }

  @Emit('backToInput')
  backToInput() {}

  @Emit('close')
  async saveReport() {
    const name = (this.config && this.config.name) || 'Report';
    const latestInvoice = await this.reportService.getLatestInvoice();
    this.loanReports.forEach(async (value: LoanReport, index: number) => {
      if (value.report.length > 0) {
        const reportPdfConfig: any = {
          orientation: 'portrait',
          unit: 'mm',
        }
        const reportPdf: jsPDF = new jsPDF(reportPdfConfig);

        const { lender, report, lineItems } = value;
        const invoiceNumber = parseFloat(latestInvoice.invoiceNumber != null ? (parseFloat(latestInvoice.invoiceNumber) + index + 1).toString() : '20000001');
        // this.buildLenderPagesLocally(reportPdf, lender, report, lineItems, invoiceNumber.toString());
        this.buildLenderPagesOnServer(reportPdfConfig, lender, report, lineItems, invoiceNumber.toString());
        await this.createReport(name, lender, report, lineItems, invoiceNumber.toString(), reportPdf);
        this.markParcelsBilled(this.unbilledParcels[value.lender.lenderId], `${name}_${invoiceNumber}`);
        // reportPdf.save(`${name}_${lender.id}.pdf`);
      }
    });
  }

  // parcels billed == set when_billed to current date for all the parcels part of the report.
  async markParcelsBilled(unbilledParcels: any[], billedReport: string) {
    const payload: JsonPatchPayload = [];
    unbilledParcels.forEach((parcel) => {
      payload.push({
        op: JsonPatchOperator.replace,
        path: `/${parcel.loanId}/parcels/${parcel.parcelId}/when_billed`,
        value: DateTime.local().toJSDate(),
      });
      payload.push({
        op: JsonPatchOperator.replace,
        path: `/${parcel.loanId}/parcels/${parcel.parcelId}/billed_report`,
        value: billedReport,
      });
    });
    for (let i = 0; i < payload.length; i += 50) {
      await this.service.batchPatchLoans(payload.slice(i, i + 50)); // eslint-disable-line
    }
  }
}
