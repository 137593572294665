



















































import {
  Component, Mixins, Vue,
} from 'vue-property-decorator';
import { Route } from 'vue-router';

import UserPermissions from '@/mixins/UserPermissions.vue'

import BillingDataReportInput from '@/views/billing/BillingDataReportInput.vue';
import BillingPanel from '@/views/billing/BillingPanel.vue';
import Loan from '@/entities/Loan';
import Lender from '@/entities/Lender';
import ReportSummary from '@/views/billing/ReportSummary.vue';

@Component({
  name: 'billing',
  beforeRouteLeave(to: Route, from: Route, next: Function) {
    const that = this as Billing;

    if ((that.showReport || that.showBillingForm) && that.forceLeave) {
      that.showEditDialog = true;
      that.intendedRoute = to;
      next(false);
    } else {
      that.intendedRoute = null;
      next();
    }
  },
  components: {
    BillingDataReportInput,
    ReportSummary,
    BillingPanel,
  },
})
export default class Billing extends Mixins(UserPermissions) {
  private reportConfig: any = {};

  private showReport: boolean = false;
  private forceLeave: boolean = false;
  private showEditDialog: boolean = false;
  private showBillingForm: boolean = false;

  private intendedRoute?: Route;

  private reportedLenders: Lender[] = [];

  private reportedLoans: Loan[] = [];

  private optionals: string[] = [];

  async created() {
    this.forceLeave = true;
  }

  navigateAway() {
    this.forceLeave = false;
    if (this.intendedRoute) {
      this.$router.push(this.intendedRoute.path);
    }
  }

  dismissEditDialog() {
    this.showEditDialog = false;
  }

  toggleBillingForm() {
    this.showBillingForm = !this.showBillingForm;
  }

  toggleState() {
    this.showReport = !this.showReport;
  }

  clearLoans() {
    this.reportedLoans = [];
    this.toggleState();
  }

  runReport(payload: any) {
    this.reportedLenders = payload.lenders;
    this.reportedLoans = payload.loans;
    this.reportConfig = payload.config;
    this.toggleState();
  }
}
